import React from 'react'

const About = () => {
    return (
        <div id="articles" className="about-area border-bottom-two three ptb-100">
            <div className="container align-items-center">
                <div className="row align-items-center">

                    <div>
                        <div className="about-content about-content--techforgood">
                            <div className="section-title three">

                                <h2 ><span>Articles</span></h2><p><span>A collection of articles by and about.</span></p>
                                <ul>
                                    <li><a href="https://technical.ly/company-culture/philly-climate-change-azavea-world-affairs-council/">Dec 21, 2022 - How can your tech company address climate change? - technical.ly</a></li>
                                    <li><span><a href="https://blog.rebel.com/digital-and-tech-hacks-to-reduce-enviro-impact/">April 2021 - 7 Digital and Technology Hacks to Reduce Your Organization’s Environmental Impact</a></span></li>
                                <li><span><a href="https://obj.ca/index.php/article/technology-good-new-super-power-here-how-you-can-harness-it">Apr 15, 2019 - Technology for good is a new super power. Here is how you can harness it.</a></span></li>
                                <li><span><a href="https://medium.com/nomadic-labs/from-random-hacks-to-the-future-of-kindness-c7c774b4836f">Apr 30, 2019 - From Random Hacks to the Future of Kindness</a></span></li><li><span><a href="https://www.linkedin.com/pulse/estimote-beacons-canadian-museum-nature-brett-tackaberry/">July 12, 2015 - Estimote beacons at the Canadian Museum of Nature</a></span></li><li><span><a href="https://apt613.ca/meet-this-months-other-charity-mustache-growers/">November 24, 2015 - Meet this month’s other charity mustache growers</a></span></li></ul>
                                
                                <h2><span>Presentations</span></h2>
                                <p><span>Events, Presentations and Panels over the years.</span></p>

                                <h3>WMO Workshop on Data-Proximate Compute</h3>
                                <p>September 2024 ◸ <a href="https://tackaberry.github.io/presentations/WMO-Workshop--From-Cloud-to-Edge-September-2024.pdf" target="_blank" rel="noreferrer" >Download</a></p>
                                <p>By distributing computing power closer to the data source (the "edge"),
                                meteorological operations can achieve lower latency, faster responses, and localized
                                processing. This is critical for weather forecasting, early warning systems, and
                                disaster response, where timely and accurate data is essential. Cloud-to-edge
                                solutions also enable better handling of massive datasets, ensuring that global
                                meteorological services are resilient, scalable, and able to support growing
                                demands.
                                Knowing the capabilities and tradeoffs of cloud and edge will help you build the most
                                effective hybrid cloud strategy. </p>

                                <h3>AI Tinkerers Ottawa</h3><p>August 2024 ◸ <a href="https://tackaberry.github.io/presentations/AI-Tinkerers-Ottawa-August-2024.pdf" rel="noreferrer" target="_blank">Download</a></p>

                                <h3>Cloud Sustainability: A Practical Guide for Building a More Sustainable Internet</h3>
                                <p>Novemeber 2023 ◸ <a href="https://github.com/tackaberry/cncf-cloud-sustainability" rel="noreferrer" target="_blank">Download</a></p>

                                <h3>W3C Web Sustainability Guidelines</h3>
                                <p>November 2023 ◸ <a href="https://github.com/tackaberry/ottawajs-w3c-web-sustainability-guidelines">Download</a></p>

                                <h3><span>How to Stay Safe &amp; Secure Online: Cybersecurity Fundamentals</span></h3><p><span>Thursday, March 30, 2023 @ 12- 1PM ◸ </span><span><a href="https://github.com/tackaberry/impact-hub-bbl-cybersecurity-fundamentals">Source code</a></span><span>&nbsp;◸ </span><span><a href="http://impact-hub-bbl-cybersecurity-fundamentals.s3-website.ca-central-1.amazonaws.com/">Slides</a></span></p><p><span>In today's digital age, it's more important than ever to be aware of cybersecurity threats and take proactive steps to protect yourself and your business. Learn about the basics of cybersecurity, including the common types of cyber attacks, how to identify them, and how to prevent them.</span></p>

                                <h3><span>What is web3 &amp; Why Should You Care?</span></h3><p><span>Thursday, June 9, 2022 @ 12- 1PM ◸ </span><span><a href="https://github.com/tackaberry/impact-hub-bbl-web3-intro">Source code</a></span><span>&nbsp;◸ </span><span><a href="http://impact-hub-bbl-web3-intro.s3-website.ca-central-1.amazonaws.com/">Slides</a></span></p><p><span>Web3 is an emerging structure for the next generation of the internet. Whether you know it or not, it’s already starting to change the way you live and work online.</span></p>

                                <h3><span>How Technology and the People Behind it are Making the World a Better Place</span></h3><p><span>Thursday, June 3, 2021 @ 12- 1PM ◸ </span><span><a href="https://github.com/tackaberry/impact-hub-bbl-tech4good">Source code</a></span><span>&nbsp;◸ </span><span><a href="https://www.youtube.com/watch?v%3D2glz65XJmPM">Youtube</a></span></p><p><span>Delve into the changing role being played by big and small tech corporations to increase their impact and make the world a better place</span></p>
                                
                                <h3><span>The Environmental Impact of Your Digital Business</span></h3><p><span>Thu, March 11, 2021 ◸ </span><span><a href="https://github.com/tackaberry/envirocentre-environmental-impact-of-your-digital-business">Source code</a></span><span>&nbsp;◸ </span><span><a href="https://www.youtube.com/watch?v%3DzvnVRg-Bi8A">Youtube</a></span></p><p><span>From sending emails to web searches to where your website is hosted - small decisions and actions have an impact on our environment. You can reduce your business's digital carbon footprint and energy use just by knowing the impact of your day-to-day digital choices. There's always a cleaner way and we'll explore your options.</span></p><h3><span>Engagements at Algonquin College</span></h3><p><span><a href="https://github.com/tackaberry/algonquin-professional-practice">A Presentation for Algonquin College Professional Practice</a></span></p><p><span><a href="https://github.com/tackaberry/algonquin-iawd-trends-web-development">Presentation for Algonquin College IAWD Trends in Web Development</a></span></p><p><span>◸ plus many more over the years</span></p><h3><span>Using Technology for Good / Beyond the Bake Sales</span></h3><p><span>Dec 2019 ◸ </span><span><a href="https://open.spotify.com/episode/2hGlZnJummZdXBrkCbMITa">Listen on Spotify</a></span></p><h3><span>Cloudfest 2018 - Growth and Change</span></h3><p><span>April 2018 ◸ </span><span><a href="https://www.youtube.com/watch?v%3Dd_hBpjvuPXY">Watch on Youtube</a></span></p><h3><span>Other Presentations and Conferences</span></h3><ul><li><span>June 2021 - Project In-Kind Impact Summit</span></li><li><span>Feb 2020 - Code for Canada Summit, Hosted Panel on Tech4Good and Civic Tech</span></li><li><span>Feb 2010 - OLA Super Conference, Online Book Club for Kids</span></li><li><span>Sep 2015 - IoT613, The Future of IoT, Panel Discussion</span></li></ul>


                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About